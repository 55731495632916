<template>
  <base-view
      @click:config="config"
      @update:search-values="updateSearch"
      :search-columns="searchColumns">

    <create-edit-mensagem-dialog :item="item" 
                                :clientes-selecionados="linhasSelecionadas"
                                :search-values="searchValues"
                                v-model="editDialogOpened"
                                @onSave="() => $refs.dataTable.refresh()"/>

    <div>
      <mensagens-data-table ref="dataTable" 
                            :selection.sync="selection"
                            :search-values="searchValues"/>
    </div>
  </base-view>

</template>

<script>
import MensagensDataTable from "../../components/shared/datatables/MensagensDataTable";
import BaseView from "../BaseView";
import CreateEditMensagemDialog from "../../components/shared/dialogs/CreateEditMensagemDialog";
import {ItemDeDominio} from "../../api/itemsDominio";
import {findAll as findAllContrato} from '../../api/contrato';
import {findAll as findAllContratado} from '../../api/contratado';
import {mapActions} from "vuex";

export default {
  name: "index",
  props: {
    
  },
  components: {CreateEditMensagemDialog, BaseView, MensagensDataTable},
  data() {
    return {
      selection: [],
      item: null,
      editDialogOpened: false,
      selectedItems: [],
      searchValues: {},
      searchColumns: [
        {
          name: 'Tipo Comunicação',
          column: 'tipoComunicacao',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.ARR_TIPO_MENSAGEM
          })
        },      
        {
          name: 'Contrato',
          column: 'idContrato',
          type: 'autocomplete',
          optionsFactory: (search) => findAllContrato(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricaoCompleta
                }))
              })
        },
        {
          name: 'Status do Contrato',
          column: 'status',
          type: 'select',
          optionsFactory: () => this.fetch({
            itemDominio: ItemDeDominio.STATUS_CONTRATO
          })
        },           
        {
          name: 'Contratado',
          column: 'idContratado',
          type: 'autocomplete',
          optionsFactory: (search) => findAllContratado(1,{
            nome: search
          })
              .then(r => {
                return r.elements.map(e => ({
                  key: e.id,
                  valor: e.descricao
                }))
              })
        },        
      ]
    }
  },
  computed: {
    linhasSelecionadas() {
      return this.selection.map(s => s.idMensagem);
    }
  },
  methods: {
    ...mapActions('itemsDominio', ['fetch']),
    updateSearch(search) {
      this.searchValues = search;
    },
    config() {
      this.item = {};
      this.editDialogOpened = true;
    }
  },
}
</script>

<style scoped lang="scss">

::v-deep {
  .v-data-table__wrapper {
    height: calc(100vh - 157px);
  }
}

</style>