<template>
  <v-row>
    <v-col cols="12">
      <div><strong>{{tipoComunicacaoSelecionado}}</strong> - <strong>{{qtdClientesSelecionados}}</strong></div>
      <v-divider/>
    </v-col>
    <v-col cols="12" md="8">
      <v-text-field 
          label="Informe um assunto para essa(s) mensagem(ns)"
          dense
          value=""
          v-model.trim="value.descricao"
          :rules="[
                      this.$validators.string.required
                  ]"/>
    </v-col>
    <v-col cols="12" md="4">
      <lookup-dominio label="Enviar por"
                      v-model="value.tipoEnvioMensagem"
                      :type="lookups.tipoEnvioMensagem"
                      :hidden-clear="true"
                      :rules="[
                          this.$validators.string.required
                      ]"/>
    </v-col>
    <v-col cols="12" sm="4" >
      <v-radio-group v-model="value.vaiEnviarImagem" row 
          :rules="[
                this.$validators.string.required,
          ]">
        <v-radio
          label="Sem anexo"
          value="N"
        ></v-radio>
        <v-radio
          label="Com anexo"
          value="S"
        ></v-radio>        
      </v-radio-group>
    </v-col>
    <v-col cols="12" md="8" v-show="value.vaiEnviarImagem=='S'">
      <v-file-input
        accept=".jpg, .jpeg, .png, .gif, .mp4"
        label="Escolha uma imagem ou um vídeo de até 20MB"
        v-model="value.caminhoImagem"
        :rules="[
            v => this.$validators.number.tamanhoMaxArquivo(v, 20)
          ]"
        ></v-file-input>
    </v-col>
    <v-col cols="12">
      <v-textarea
          label="Texto da mensagem"
          counter
          filled
          prepend-icon="mdi-comment"
          rows="7"
          v-model="value.mensagem" 
          :rules="[
                      this.$validators.string.required
                  ]"
        ></v-textarea>
    </v-col>
    <v-col cols="12" class="text-caption">
      <v-divider/>
      <div>Ao programar o envio da(s) mensagens(s), você poderá usar uma ou mais chaves abaixo e o sistema substituirá automaticamente pelo valor correspondente a cada linha marcada</div>
      <ul>
        <li>{nome} - Nome do Cliente</li>
        <li>{mes} - Mês referência (boleto ou aniversariante do mês)</li>
        <li>{vencimento} - Vencimento do boleto</li>
        <li>{valor} - Valor do boleto</li>
        <li>{desconto} - Desconto: só é exibido caso o cliente tenha desconto e fatura esteja em dia</li>
      </ul>
      <v-divider/>
    </v-col>
  </v-row>
</template>

<script>
import {ItemDeDominio} from "../../../api/itemsDominio";
import LookupDominio from "../common/LookupDominio";
import SearchBox from "../common/SearchBox";
import Vue from 'vue';

export default {
  name: "MensagemForm",
  components: {SearchBox, LookupDominio},
  props: {
    value: {
      type: Object
    },
    clientesSelecionados: {
      type: Array
    },
    searchValues: {
      type: Object
    }
  },
  data() {
    return {
      lookups: {
        tipoEnvioMensagem: ItemDeDominio.TIPO_ENVIO_MENSAGEM,
      },
      mensagemTipo4: `Prezado(a) {nome},
\nSegue boleto referente as aulas do mês de {mes}, com vencimento em {vencimento} no valor de R$ {valor}{desconto}.
Evite o pagamento de multas e juros pagando até a data de vencimento.
Segue boleto em anexo.

Att,
equipe Gestão de Contrato.`,
      mensagemTipo5: `Prezado(a) {nome},
\n...

Att,
Gestão de Contrato.`,
      mensagemTipo6: `Hoje é um dia muito especial!
Todos os colaboradores da Gestão de Contrato desejam a você muita saúde e felicidades.

Equipe Gestão de Contrato`,

      mensagemTipo7: `Olá {nome}...

Att,
Gestão de Contrato.`,
      mensagemTipo8: `Olá {nome}...
\n....

Att,
Gestão de Contrato.`,
    }
  },
  computed: {
    qtdClientesSelecionados() {
      return "" + this.clientesSelecionados.length + " cliente(s) selecionado(s).";
    },
    tipoComunicacaoSelecionado() {
      let desTipoComunicacao = "";
      if (this.searchValues.tipoComunicacao === 4) desTipoComunicacao = "Enviar notificação de faturas em aberto e não vencidas";
      if (this.searchValues.tipoComunicacao === 5) desTipoComunicacao = "Enviar notificação de faturas atrasadas";
      if (this.searchValues.tipoComunicacao === 6) desTipoComunicacao = "Enviar notificação de aniversariantes do mês";
      if (this.searchValues.tipoComunicacao === 7) desTipoComunicacao = "Comunicação Geral";
      if (this.searchValues.tipoComunicacao === 8) desTipoComunicacao = "Enviar notificação planos semestral/trimestral";
      return desTipoComunicacao == "" ? "Nenhum Tipo de comunicação selecionado" : "Tipo de comunicação selecionado: " + desTipoComunicacao;
    },
  },
  methods: { 
    getTextoMensagem() {
      if (this.searchValues.tipoComunicacao === 4) Vue.set(this.value,'mensagem',this.mensagemTipo4);
      if (this.searchValues.tipoComunicacao === 5) Vue.set(this.value,'mensagem',this.mensagemTipo5);
      if (this.searchValues.tipoComunicacao === 6) Vue.set(this.value,'mensagem',this.mensagemTipo6);
      if (this.searchValues.tipoComunicacao === 7) Vue.set(this.value,'mensagem',this.mensagemTipo7);
      if (this.searchValues.tipoComunicacao === 8) Vue.set(this.value,'mensagem',this.mensagemTipo8);
    },
    setVaiEnviarImagem() {
      Vue.set(this.value,'vaiEnviarImagem','N');
    },
  },
  mounted(){
    this.getTextoMensagem();
    this.setVaiEnviarImagem();
  }
}
</script>

<style scoped>

</style>